<template>
  <div v-if="nation" class="d-flex flex-row address-card-custom">
    <div class="w-100 flex-shrink-0 offcanvas-desktop">
      <div class="navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around" role="tablist">
        <template v-for="(comp, idx) in componentList">
          <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
            <router-link :is="comp.isDisabled ? 'span' : 'router-link'" active-class="active" class="navi-link py-4"
              :class="{
                'cursor-not-allowed': comp.isDisabled,
                'cursor-pointer': !comp.isDisabled,
                disabled: comp.isDisabled,
              }" :data-tab="idx" data-toggle="tab" role="tab" aria-selected="false" :to="{ name: comp.routeName }">
              <span class="navi-icon">
                <i :class="comp.icon"></i>
              </span>
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
      <div class="card card-custom ">
        <div class="card-body">
          <div class="d-flex align-items-center pb-7">
            <country-flag class="mr-3" :big="true" :country-iso="nation.iso"></country-flag>
            <div>
              <a class="ml-5 font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ nation.name }}</a>
            </div>
          </div>
          <div class="mt-7">
            <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
              {{ $t("Iso") }}:
              <span class="font-weight-normal">{{ nation.iso }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
              {{ $t("Iso3") }}:
              <span class="font-weight-normal">{{ nation.iso3 }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
              {{ $t("Currency name") }}:
              <span class="font-weight-normal">{{ nation.currency_name }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
              {{ $t("Currency symbol") }}:
              <span class="font-weight-normal">{{ nation.currency_symbol }}</span>
            </div>
            <div class="separator separator-dashed my-10"></div>

          </div>

          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <template v-for="(comp, idx) in componentList">
              <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
                <router-link :is="comp.isDisabled ? 'span' : 'router-link'" active-class="active" class="navi-link py-4"
                  :class="{
                    'cursor-not-allowed': comp.isDisabled,
                    'cursor-pointer': !comp.isDisabled,
                    disabled: comp.isDisabled,
                  }" :data-tab="idx" data-toggle="tab" role="tab" aria-selected="false" :to="{ name: comp.routeName }">
                  <span class="navi-icon">
                    <i :class="comp.icon"></i>
                  </span>
                  <span class="navi-text font-size-lg">{{ comp.name }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid ml-lg-8">
      <router-view :key="$route.fullPath" v-bind="currentDetailComponentProps"
        v-on="currentDetailComponentEvents"></router-view>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import NationsCountryService from "@/core/services/nation/nations-country.service";
import NationsAdministrativeStructureService from "@/core/services/nation/nations-administrativestructures.service";
import NationsGroupsService from "@/core/services/nation/nations-groups.service";

export default ({
  components: {
    CountryFlag
  },
  data() {
    return {
      nation: null,
      legalTypes: [],
      administrativeStructures: [],
      nationsGroups: [],
      totalNationGroups: 0,
      nationalContracts: [],
      isLoadingAdministrative: false,
    }
  },
  computed: {
    currentDetailComponentProps() {
      return this.currentDetail?.props;
    },

    currentDetailComponentEvents() {
      return this.currentDetail?.events;
    },
    currentDetail() {
      return this.componentList.find((item) => item.routeName == this.$route.name);
    },
    componentList() {
      return [
        {
          routeName: "detail-nation-information",
          name: this.$t("Information"),
          icon: "flaticon2-information",
          canShow: true,
          props: {
            nation: this.nation,
          },
          events: {
            updated: this.loadNation,
          },
        },
        {
          routeName: "detail-nation-secondments",
          name: this.$t("Secondment settings"),
          icon: "flaticon2-gear",
          canShow: true,
          props: {
            nation: this.nation,
          },
          events: {
            updated: this.loadNation,
          },
        },
        {
          routeName: "detail-nation-legal-type",
          name: this.$t("Legal type"),
          icon: "flaticon2-map",
          canShow: true,
          props: {
            nation: this.nation
          },
          events: {
          },
        },
        {
          routeName: "detail-nation-administrative",
          name: this.$t("Administrative structures"),
          icon: "flaticon2-protection",
          canShow: true,
          props: {
            nation: this.nation,
            administrativeStructures: this.administrativeStructures,
            isLoadingAdministrative: this.isLoadingAdministrative
          },
          events: {
            updated: this.loadNation,
          },
        },
        {
          routeName: "detail-nation-prices",
          name: this.$t("Price list"),
          icon: "flaticon2-document",
          canShow: true,
          props: {
            nation: this.nation,
          },
          events: {
            updated: this.loadNation,
          },
        },
        {
          routeName: "detail-nation-groups",
          name: this.$t("Nation groups"),
          icon: "flaticon2-group",
          canShow: true,
          props: {
            nation: this.nation,
          },
          events: {
          },
        },
        {
          routeName: "detail-nation-contracts",
          name: this.$t("National contracts"),
          icon: "flaticon-folder",
          canShow: true,
          props: {
            nation: this.nation,
          },
          events: {
            updated: this.loadNation,
          },
        },
      ];
    },
    nationIso() {
      return this.$route.params.nationId;
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Nations"), route: { name: "manage-nations" } },
      { title: this.$t("Details") },
    ]);

    await this.loadNation();
    await this.loadLegalTypes();
    await this.loadAdministrativeStructures();
    await this.loadNationGroups();
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    async loadNation() {
      try {
        const res = await NationsCountryService.getOne(this.nationIso, true);
        this.nation = res;
      } catch (err) {
        console.log(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      }
    },
    async loadLegalTypes() {

    },
    async loadAdministrativeStructures() {
      try {
        this.isLoadingAdministrative = true;
        const res = await NationsAdministrativeStructureService.getAllAdministrativeStructures(this.nation.iso);
        this.administrativeStructures = res.results;
        this.isLoadingAdministrative = false;
      } catch (err) {
        console.log(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      }
    },
    async loadNationGroups() {
      try {
        const res = await NationsGroupsService.getGroups();
        this.totalNationGroups = res.count;
        this.nationsGroups = res.results;

      } catch (err) {
        console.log(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      }
    },
  }

})
</script>
