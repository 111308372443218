import api from "../api.service";

class NationsAdministrativeStructureService {
  async getAllAdministrativeStructures(iso, perPage, page, sortBy = null, search = null, sortDesc = null, parent = null, expand = null, omit = null,
    fields = null) {
    const offset = (page - 1) * perPage;
    const params = {
      territorial_jurisdiction: iso,
      limit: perPage,
      offset,
      search,
      parent: parent,
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`nations-administrativestructure/`, {
      params
    });
    return res.data;
  }
  async getOne({
    id,
    expand,
    omit,
    fields
  }) {
    const params = {};
    if (expand && expand.length) {
      params.expand = expand;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }

    if (fields && fields.length) {
      params.fields = fields;
    }
    const r = await api.get(`nations-administrativestructure/${id}/`, {
      params,
    });
    return r.data;
  }
}



export default new NationsAdministrativeStructureService();