var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.nation ? _c('div', {
    staticClass: "d-flex flex-row address-card-custom"
  }, [_c('div', {
    staticClass: "w-100 flex-shrink-0 offcanvas-desktop"
  }, [_c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c(comp.isDisabled ? 'span' : 'router-link', {
      tag: "router-link",
      staticClass: "navi-link py-4",
      class: {
        'cursor-not-allowed': comp.isDisabled,
        'cursor-pointer': !comp.isDisabled,
        disabled: comp.isDisabled
      },
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })])])], 1) : _vm._e()];
  })], 2)]), _c('div', {
    staticClass: "flex-row-auto offcanvas-mobile w-300px w-xl-350px"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center pb-7"
  }, [_c('country-flag', {
    staticClass: "mr-3",
    attrs: {
      "big": true,
      "country-iso": _vm.nation.iso
    }
  }), _c('div', [_c('a', {
    staticClass: "ml-5 font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
  }, [_vm._v(_vm._s(_vm.nation.name))])])], 1), _c('div', {
    staticClass: "mt-7"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Iso")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.nation.iso))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Iso3")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.nation.iso3))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Currency name")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.nation.currency_name))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Currency symbol")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.nation.currency_symbol))])]), _c('div', {
    staticClass: "separator separator-dashed my-10"
  })]), _c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c(comp.isDisabled ? 'span' : 'router-link', {
      tag: "router-link",
      staticClass: "navi-link py-4",
      class: {
        'cursor-not-allowed': comp.isDisabled,
        'cursor-pointer': !comp.isDisabled,
        disabled: comp.isDisabled
      },
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })]), _c('span', {
      staticClass: "navi-text font-size-lg"
    }, [_vm._v(_vm._s(comp.name))])])], 1) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "flex-row-fluid ml-lg-8"
  }, [_c('router-view', _vm._g(_vm._b({
    key: _vm.$route.fullPath
  }, 'router-view', _vm.currentDetailComponentProps, false), _vm.currentDetailComponentEvents))], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }